import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ClipboardButton } from '../../components/ClipboardButton';

export interface IframeDialogProps {
  url: string;
  open: boolean;
  title?: string;
  large?: boolean;
  onClose: () => void;
}

export default function IframeDialog(props: IframeDialogProps) {
  const theme = useTheme();
  const { open, title, onClose, url } = props;
  const [sizeInit, setSizeInit] = useState(false);
  const [largeFlag, setLargeFlag] = useState(false);
  const smOrDown = useMediaQuery(theme.breakpoints.down('md'));

  const handleDialogClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (sizeInit) return;
    if (!smOrDown && props.large) {
      setLargeFlag(true);
    }
    setSizeInit(true);
  }, [sizeInit, props.large, smOrDown]);

  const smDownFull = largeFlag && smOrDown;

  return (
    <Dialog
      className="IframeDialog-root"
      open={open}
      fullWidth
      fullScreen={smDownFull}
      maxWidth={largeFlag ? 'md' : 'sm'}
      onClose={handleDialogClose}
      aria-labelledby="document-dialog"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 1,
          py: 1,
        }}
      >
        <Box display="flex" justifyContent="center">
          <IconButton
            onClick={() => {
              setLargeFlag((prev) => !prev);
            }}
            size="small"
          >
            {largeFlag ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          {title && <Typography variant="subtitle1">{title}</Typography>}
        </Box>
        <IconButton onClick={handleDialogClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers sx={{ p: 0 }}>
        <Box
          sx={{
            height: 'calc(100dvh - 178px)',
            display: 'flex',
            flexDirection: 'column',
            '& .IframeDialog-iframe': {
              width: '100%',
              flex: 1,
              border: 'none',
            },
          }}
        >
          <iframe className="IframeDialog-iframe" src={url} title="dialog content frame" />
        </Box>
      </DialogContent>
      <DialogActions disableSpacing>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" px={1}>
          <Box>
            <ClipboardButton text="주소복사" />
            <Tooltip title="새창">
              <IconButton
                aria-label="OpenInNew"
                href={url}
                target="_blank"
                style={{ marginLeft: 8 }}
                size="large"
              >
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Button onClick={onClose} size="small">
              Close
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
