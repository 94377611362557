'use client';

import type { BreadcrumbsProps } from '@/components/CustomBreadcrumbs';
import CustomBreadcrumbs from '@/components/CustomBreadcrumbs';
import { Box, styled, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useMainLayoutStore } from '@repo-ui/store/useMainLayoutStore';
import React, { useEffect } from 'react';
import DrawerHeader from './components/DrawerHeader';
import ElevationScroll from './components/ElevationScroll';
import Footer from './components/footer/Footer';
import Sidebar from './components/sidebar/Sidebar';
import MainLayoutTopbar from './components/topbar/MainLayoutTopbar';
import { mainLayoutConstants as LAYOUT } from './main-layout-constants';

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'contentShift',
})<{
  contentShift: number;
}>(({ theme, contentShift }) => ({
  flexGrow: 1,
  position: 'relative',
  padding: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  marginLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100dvh',
  backgroundColor: LAYOUT.contentBgColor,
}));

interface Props {
  title?: string;
  hideTopbar?: boolean;
  hideFooter?: boolean;
  hideLoginButton?: boolean;
  children?: React.ReactNode;
  breadcrumbProps?: BreadcrumbsProps;
}

export default function MainLayout(props: Props) {
  const {
    title,
    hideTopbar = false,
    hideLoginButton = false,
    children,
    breadcrumbProps,
    hideFooter = false,
  } = props;
  const mainLayoutStore = useMainLayoutStore((state) => state);
  const smallScreen = useMediaQuery('(max-width:1200px)');
  const isSidebarOpen = mainLayoutStore.isSidebarOpened;

  useEffect(() => {
    if (!smallScreen && isSidebarOpen) {
      mainLayoutStore.setSidebarOpened(false);
    }
  }, [smallScreen, isSidebarOpen, mainLayoutStore]);

  return (
    <Box
      className="MainLayout-root"
      sx={{
        position: 'relative',
        minHeight: '100dvh',
      }}
    >
      {!hideTopbar && (
        <ElevationScroll>
          <MainLayoutTopbar
            title={title}
            isSmallScreen={smallScreen}
            isSidebarOpen={isSidebarOpen}
            hideLoginButton={hideLoginButton}
            onClickMenuButton={() => {
              mainLayoutStore.toggleSidebarOpen();
            }}
          />
        </ElevationScroll>
      )}

      {smallScreen && (
        <Drawer
          sx={{
            width: LAYOUT.sidemenu.width,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: LAYOUT.sidemenu.width,
              boxSizing: 'border-box',
              backgroundColor: LAYOUT.sidemenu.bgColor,
              color: 'white',
            },
          }}
          variant="temporary"
          anchor="left"
          open={isSidebarOpen}
          onClose={() => {
            mainLayoutStore.setSidebarOpened(false);
          }}
        >
          <Sidebar />
        </Drawer>
      )}

      <Main contentShift={0} className="MainLayout-bodyContainer">
        {!hideTopbar && <DrawerHeader />}
        {breadcrumbProps && <CustomBreadcrumbs {...breadcrumbProps} />}
        <Box
          className="MainLayout-body"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            '& > *:first-of-type': {
              flex: 1,
            },
          }}
        >
          {children}
        </Box>
      </Main>
      {!hideFooter && <Footer />}
    </Box>
  );
}
