'use client';

import { Box, Typography } from '@mui/material';
import NextImage from 'next/image';
import NextLink from 'next/link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';
import { MENU_SECTIONS } from '../../main-layout-top-menus';
import { LOGO_WIDTH, TOPBAR_HEIGHT, resolveMenuSectionIndex } from './util';

export function TopMenu(props: {
  className?: string;
  pathname: string;
  activeMenuIndex: number;
  visibleIndicator: boolean;
  onMenuMouseEnter: (event: React.MouseEvent, menuIndex: number) => void;
  onMenuMouseLeave: React.MouseEventHandler;
  onChangeMeasure: () => void;
}) {
  const { activeMenuIndex, visibleIndicator, pathname, onMenuMouseEnter, onMenuMouseLeave } = props;
  const activeSectionIndex = useMemo(() => resolveMenuSectionIndex(pathname), [pathname]);
  // top menu의 abs 좌표가 변경된 경우 호출, 서브메뉴의 왼쪽 위치를 계산하는 용도
  const onChangeMeasureRef = useRef(props.onChangeMeasure);
  onChangeMeasureRef.current = props.onChangeMeasure;
  const [topMenuBoxRef, { x, left, width, height }] = useMeasure<HTMLDivElement>();
  const [indicatorOffsetWidth, setIndicatorOffsetWidth] = useState<number>(-1);

  // 윈도우 크기가 변경된 경우에도 onChangeMeasure()를 호출한다.
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  useEffect(() => {
    onChangeMeasureRef.current?.();
  }, [x, left, width, height, windowWidth, windowHeight]);

  const handleMenuClick = (sectionIndex: number, event: React.MouseEvent<HTMLElement>) => {};

  // 메뉴 onMouseEnter - 서브메뉴 표시
  const handleMenuMouseEnter = (event: React.MouseEvent) => {
    const elem = event.target as HTMLElement;
    const idx = elem.dataset['sectionIndex'];
    if (typeof idx !== 'string') return;
    onMenuMouseEnter(event, +idx);
    setIndicatorOffsetWidth(elem.offsetWidth);
  };

  return (
    <>
      <Box
        className="TopMenu-logo"
        sx={{
          ml: 2,
          mt: 1,
          '& a': {
            width: LOGO_WIDTH,
          },
        }}
      >
        <NextLink href="/" aria-label="move to home">
          <NextImage
            src="/_static/images/ocode_logo_white.png"
            alt=""
            width={168}
            height={52}
            priority
          />
        </NextLink>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="100%"
        flexGrow={1}
        mr={2}
      >
        {/* 메뉴 인디케이터를 표시하기 위해 menuBar를 sectionContainer 감싼다 */}
        <Box
          className="TopMenu-menuBox"
          ref={topMenuBoxRef}
          sx={{
            width: 640,
            position: 'relative',
            mr: 9,
          }}
        >
          <Box
            component="ul"
            sx={{
              width: 640,
              display: 'flex',
              alignItems: 'center',
              m: 0,
              p: 0,
            }}
          >
            {MENU_SECTIONS.map((section, idx) => (
              <Box
                component="li"
                key={section.title}
                sx={{
                  textAlign: 'center',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  listStyle: 'none',
                  flex: 1,
                  height: TOPBAR_HEIGHT,
                  py: 1,
                  px: 2,
                  color: '#fff',
                  '&:hover': {
                    color: 'primary.main',
                  },
                  '&.Mui-active': {
                    color: 'primary.main',
                  },
                }}
                className={idx === activeSectionIndex ? 'Mui-active' : undefined}
                data-section-index={idx}
                style={{ cursor: 'default' }}
                onClick={(e) => {
                  handleMenuClick(idx, e);
                }}
                onMouseEnter={handleMenuMouseEnter}
                onMouseLeave={onMenuMouseLeave}
              >
                <Typography variant="h6">{section.title}</Typography>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              content: "''",
              position: 'absolute',
              bottom: 1,
              transition: '0.5s',
              width: '15%',
              height: 3,
              background: '#00ACFF',
              display: visibleIndicator ? 'block' : 'none',
              // left:
              //   activeMenuIndex >= 0
              //     ? `${activeMenuIndex * (100 / MENU_SECTIONS.length)}%`
              //     : undefined,
              left:
                activeMenuIndex >= 0
                  ? (activeMenuIndex + 1) * indicatorOffsetWidth - indicatorOffsetWidth / 2
                  : undefined,
              transform: 'translateX(-50%)',
            }}
          />
        </Box>
      </Box>
    </>
  );
}
